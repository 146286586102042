import { Component, ViewEncapsulation } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { Router, RouterModule } from '@angular/router';
import { MyEmitterService } from '../../app-emitter.service';
import { RouteConstants } from '../../config/route.config';
import { Apollo } from "apollo-angular"
import { CommonModule, DatePipe, NgStyle } from '@angular/common';
import { Title, Meta, DomSanitizer, SafeResourceUrl, SafeHtml } from '@angular/platform-browser';
import { NguCarousel, NguCarouselConfig, NguCarouselDefDirective, NguCarouselNextDirective, NguCarouselPrevDirective, NguItemComponent, NguTileComponent } from '@ngu/carousel';
import { Subject, Subscription } from 'rxjs';
import META_QUERY from '../../queries/Meta/meta.service';
import { FetchMetaService } from '../../queries/Meta/fetchmeta.service';
import BLOG_QUERY from '../../queries/blogs/blogs.service';
import { takeUntil } from 'rxjs/operators';
import { FormsModule } from '@angular/forms';
import { HttpLink } from 'apollo-angular/http';
import { GraphQLModule } from '../../graphql.module';
import { MatTabsModule } from '@angular/material/tabs';
import { CanonicalService } from '../../queries/canonical/canonical.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  standalone: true,
  imports: [NgStyle, CommonModule,
    FormsModule,
    NguCarousel,
    NguCarouselDefDirective,
    NguCarouselNextDirective,
    NguCarouselPrevDirective,
    NguItemComponent,
    NguTileComponent,
    SharedModule,
    MatTabsModule,
    GraphQLModule,
    RouterModule
  ],
  providers: [DatePipe, Apollo, Title, Meta, MyEmitterService, FetchMetaService, HttpLink],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent {
  constructor(
    private router: Router,
    private emitter: MyEmitterService,
    private apollo: Apollo,
    private datepipe: DatePipe,
    private titleService: Title,
    private metaService: Meta,
    private fetchMetaService: FetchMetaService,
    public sanitizer: DomSanitizer,
    private canonicalService: CanonicalService
  ) { }

  private destroy$ = new Subject<void>()
  blogs: any;

  carouselTile: NguCarouselConfig = {
    grid: { xs: 2, sm: 2, md: 4, lg: 4, all: 0 },
    slide: 1,
    speed: 1000,
    point: {
      visible: true,
    },
    load: 4,
    velocity: 0,
    interval: {
      timing: 2000,
    },
    loop: true,
    touch: false,
    easing: 'cubic-bezier(0, 0, 0.2, 1)',
  };

  carouselVideoTile: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 3, lg: 3, all: 0 },
    slide: 1,
    point: {
      visible: true,
    },
    load: 4,
    velocity: 0,
    loop: false,
    touch: false,
    easing: 'cubic-bezier(0, 0, 0.2, 1)',
  };

  about = [
    {
      imgUrl: 'https://vcdn.cosgrid.com/website/assets3/Home/Zero%20Trust%20and%20Zero%20Glitch%20Network%20Access.webp',
      desc: '<span class="clr-blue-dark fw-bold">Zero Trust Access</span> with Ubiquitous & <span class="clr-blue-dark fw-bold">Reliable Connectivity</span>',
      alt: '',
      class: 'h-70'
    },
    {
      desc: '<span class="clr-blue-dark fw-bold">Holistic Security</span> & Advanced <span class="clr-blue-dark fw-bold">Threat Protection</span>',
      imgUrl: 'https://vcdn.cosgrid.com/website/assets3/Home/Converged%20Security%20and%20Orchestration.webp',
      alt: '',
      class: 'h-70'
    },
    {
      desc: 'Deep <span class="clr-blue-dark fw-bold">Network Visibility</span> & Seamless <span class="clr-blue-dark fw-bold">Security Orchestration</span>',
      imgUrl: 'https://vcdn.cosgrid.com/website/assets3/Home/Enhanced%20UX%20and%20Easy%20NetSecOps.webp',
      alt: '',
      class: 'h-70'
    }
  ]

  awards = [
    {
      title: 'Patent on Adaptive multi-path traffic <br/> SD-WAN',
      imgURL: 'https://vcdn.cosgrid.com/website/assets3/Home/Awards/Patent%20on%20Adaptive%20multi-path%20traffic%20SD-WAN.webp',
      width: 'w-30',
    },
    {
      title: 'Cybersecurity Grand Challenge Winner - DSCI & MeiTy',
      imgURL: 'https://vcdn.cosgrid.com/website/assets3/Home/Awards/Top%205%20Finalist%20&%20Second%20Stage%20Winner.webp',
      width: 'w-30',
    },
    {
      title: 'Winner TANSEED 2.0 - Startup TN & <br/> Govt of Tamil Nadu',
      imgURL: 'https://vcdn.cosgrid.com/website/assets3/Home/Awards/TANSEED%202.0.webp',
      width: 'w-30',
    },
    {
      title: "India's first 5G SD-WAN - Tested at 5G Test Bed - IITM",
      imgURL: "https://vcdn.cosgrid.com/website/assets3/Home/Awards/India's%20first%205G%20SD-WAN%20-%20Tested%20at%205G%20Test%20Bed%20-%20IITM.webp",
      width: 'w-30',
    },
    {
      title: 'Make in India Hardware -<br> DOT, GOI',
      imgURL: 'https://vcdn.cosgrid.com/website/assets3/Home/Awards/Make%20in%20India%20Hardware%20-%20DOT,%20GOI.png',
      width: 'w-40',
    },
    {
      title: 'Leading cybersecurity and networking solution providers on ZTNA',
      imgURL: 'https://vcdn.cosgrid.com/website/assets4/fast-mode.png',
      width: 'w-30',
    },
  ];

  ourProducts = [
    {
      link: `/${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/microzaccess`,
      imgUrl: 'https://vcdn.cosgrid.com/website/assets4/home/MicroZAccess_1.png',
      name: 'ZTNA -Zero Trust Network Access',
      width: 'w-50'
    },
    {
      link: `/${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
      imgUrl: 'https://vcdn.cosgrid.com/website/assets3/Products/SASE/2.webp',
      name: 'SASE- Secure Access Service Edge',
      width: 'w-50'
    },
    {
      link: `/${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
      imgUrl: 'https://vcdn.cosgrid.com/website/assets4/home/our-products-sig.webp',
      fragment: 'sig',
      name: 'SWG- Secure Web Gateway',
      width: 'w-50'
    },
    {
      link: `/${RouteConstants.PRODUCTS}/${RouteConstants.SD_WAN}`,
      imgUrl: 'https://vcdn.cosgrid.com/website/assets3/Products/SDWANv1/1.webp',
      name: 'Secure & Reliable Connectivity SD-WAN',
      width: 'w-50'
    },
    {
      link: `/${RouteConstants.PRODUCTS}/${RouteConstants.FIREWALL}`,
      imgUrl: 'https://vcdn.cosgrid.com/website/assets3/Products/Firewall/firewall%20logo%20cgrid.webp',
      name: 'NGFW - Next Generation Firewall',
      width: 'w-50'
    },
    {
      link: `/${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`,
      imgUrl: 'https://vcdn.cosgrid.com/website/assets3/Products/NDR/NS.svg',
      name: 'NDR- Network Detection and Response',
      width: 'w-50'
    }
  ]

  customerSliderList = [
    {
      id: 1,
      imgURL: 'https://vcdn.cosgrid.com/website/assets3/Home/Customers/The India Cements.webp',
      height: '',
      class: 'w-50',
      alt: 'Indian Cements',
    },
    {
      id: 2,
      imgURL: 'https://vcdn.cosgrid.com/website/assets3/Home/Customers/Orange Retail Finance.webp',
      height: '',
      class: 'w-50',
      alt: 'Orange Retail Finance',
    },
    {
      id: 3,
      imgURL: 'https://vcdn.cosgrid.com/website/assets3/Home/Customers/zimson-logo.avif',
      height: '',
      class: 'w-50',
      alt: 'Zimson Watches',
    },
    {
      id: 4,
      imgURL: 'https://vcdn.cosgrid.com/website/assets3/Home/Customers/Sankara%20Nethralaya.jpg',
      height: '',
      class: 'w-50',
      alt: 'Sankara Nethralaya',
    },
    {
      id: 5,
      imgURL: 'https://vcdn.cosgrid.com/website/assets3/Home/Customers/Qube.webp',
      height: '',
      class: 'w-50',
      alt: 'Qube',
    },
    {
      id: 8,
      imgURL: 'https://vcdn.cosgrid.com/website/assets3/Home/Customers/codemantra.webp',
      height: '',
      class: 'w-50',
      alt: 'codemantra',
    },
    {
      id: 7,
      imgURL: 'https://vcdn.cosgrid.com/website/assets3/Home/Customers/impiger technologies.jpg',
      height: '',
      class: 'w-50',
      alt: 'impiger technologies',
    },

    {
      id: 6,
      imgURL: 'https://vcdn.cosgrid.com/website/assets3/Home/Customers/RSC.webp',
      height: '',
      class: 'w-50',
      alt: 'RSC',
    },

    {
      id: 10,
      imgURL: 'https://vcdn.cosgrid.com/website/assets/images1/Coral-Logo-700x464-1.png',
      height: '',
      class: 'w-50',
      alt: 'Coral',
    },
    {
      id: 11,
      imgURL: 'https://vcdn.cosgrid.com/website/assets/images1/signaltron.png',
      height: '',
      class: 'w-50',
      alt: 'Signaltron',
    },
    {
      id: 12,
      imgURL: 'https://vcdn.cosgrid.com/website/assets4/home/ntc-logistic.png',
      height: '',
      class: 'w-30',
      alt: 'NTC Logistic',
    },

    {
      id: 9,
      imgURL: 'https://vcdn.cosgrid.com/website/assets3/Home/Customers/Reliance Communications.webp',
      height: '',
      class: 'w-50',
      alt: 'Reliance Communications',
    },
  ];

  Integration: any[] = [
    {
      imgURL: 'https://vcdn.cosgrid.com/website/assets/gsuite-logo.jpg',
      name: 'GSuite Logo',
      width: '60%',
      link: 'https://docs.cosgrid.com/identity-providers/google-suite',
    },
    {
      imgURL: 'https://vcdn.cosgrid.com/website/assets/okta-logo.jpg',
      name: 'Okta Logo',
      width: '50%',
      link: 'https://docs.cosgrid.com/identity-providers/okta',
    },
    {
      imgURL: 'https://vcdn.cosgrid.com/website/assets/azure-logo.png',
      name: 'Azure Logo',
      width: '80%',
      link: 'https://docs.cosgrid.com/identity-providers/azure-ad',
    },
    {
      imgURL: 'https://vcdn.cosgrid.com/website/assets/onelogin.png',
      name: 'One Login ',
      width: '60%',
      link: 'https://docs.cosgrid.com/identity-providers/one-login',
    },
  ];

  associateWith: any[] = [
    {
      // title: 'Department of Telecommunications',
      imgURL: 'https://vcdn.cosgrid.com/website/assets/images1/associations/Department of Telecommunications.png',
      alt: 'Department of Telecommunications',
      class: 'w-70',
    },
    {
      // title: 'TCOE',
      imgURL: 'https://vcdn.cosgrid.com/website/assets/images1/associations/tcoe.png',
      alt: 'tcoe',
      class: 'w-40',
    },
    {
      // title: 'TEPC',
      imgURL: 'https://vcdn.cosgrid.com/website/assets/images1/associations/tepc.png',
      alt: 'tepc',
      class: 'w-20',
    },
    {
      // title: 'iit',
      imgURL: 'https://vcdn.cosgrid.com/website/assets/images1/associations/iit.png',
      alt: 'iit',
      class: 'w-80',
    },
    {
      // title: 'Microsoft',
      // imgURL: 'https://vcdn.cosgrid.com/website/assets/images/association/microsoftImage.png',
      imgURL: 'https://vcdn.cosgrid.com/website/assets3/Home/Customers/Microsoft.webp',
      alt: 'Microsoft',
      class: 'w-50',
    },
    {
      // title: 'Intel',
      // imgURL: 'https://vcdn.cosgrid.com/website/assets/images/association/intelImage.png',
      imgURL: 'https://vcdn.cosgrid.com/website/assets3/Home/Customers/intel.webp',
      alt: 'intel',
      class: 'w-40',
    },
    {
      // title: 'Google Cloud',
      imgURL: 'https://vcdn.cosgrid.com/website/assets3/Home/Customers/Google-cloud.png',
      alt: 'Google-cloud',
      class: 'w-70',
    },
    {
      imgURL: 'https://vcdn.cosgrid.com/website/assets4/home/voice.webp',
      alt: 'voice',
      class: 'w-40',
    },
    {
      imgURL: 'https://vcdn.cosgrid.com/website/assets4/home/n-coe-logo.webp',
      alt: 'n-coe-logo',
      class: 'w-40',
    },
  ];

  videos: any[] = [
    {
      srcdoc: this.generateYoutubeSrcDoc('PUu4_-0eXSc'),
      url: this.sanitizeYoutubeUrl('https://www.youtube.com/embed/PUu4_-0eXSc'),
      title: 'COSGrid MicroZAccess - An Uncomplicated Zero Trust Network Access - Product Overview Demo',
    },
    {
      srcdoc: this.generateYoutubeSrcDoc('CXwhA86gEsM'),
      url: this.sanitizeYoutubeUrl('https://www.youtube.com/embed/CXwhA86gEsM'),
      title: 'Zero Trust Access to Cloud Application and Multi - cloud Infra using MicroZAccess - Demo Use case',
    },
    {
      srcdoc: this.generateYoutubeSrcDoc('42b0T7EwXkE'),
      url: this.sanitizeYoutubeUrl('https://www.youtube.com/embed/42b0T7EwXkE'),
      title: 'Zero Trust Security Insights #2 - ZTNA Product Considerations',
    },
    {
      srcdoc: this.generateYoutubeSrcDoc('CrzO3zA5qls'),
      url: this.sanitizeYoutubeUrl('https://www.youtube.com/embed/CrzO3zA5qls'),
      title: 'Zero Trust Security Insights #1 - Invisible the Visible',
    },
    {
      srcdoc: this.generateYoutubeSrcDoc('90BqQRpM8qo'),

      url: this.sanitizeYoutubeUrl('https://www.youtube.com/embed/90BqQRpM8qo'),
      title: 'COSGrid MicroZAccess - Concept Video - ZTNA',
    },
  ];

  whyEnterprisesChooseCosgrid = {
    imageRoute: 'https://vcdn.cosgrid.com/website/assets/home/why-choose-cosgrid/',
    data: [
      {
        image: 'innovation',
        title: '',
        desc: '<span class="clr-blue-dark fw-bold">Technological Innovations</span> like Patented Multi-Path Traffic Aggregation, Mesh VPN, NetSecOps in a single dashboard. ',
      },
      {
        image: 'dashboard',
        title: '',
        desc: '<span class="clr-blue-dark fw-bold">Leading NetSecOps</span> solution that enables Secure Network Access, Customer experience, and visibility in a <span class="clr-blue-dark fw-bold">single window.</span>',
      },
      {
        image: 'integration',
        title: '',
        desc: '<span class="clr-blue-dark fw-bold">Integrated Network Security</span> & SD-WAN platform for <span class="clr-blue-dark fw-bold">remote branch offices, WFH users, IoT & cloud access.</span>',
      },
      {
        image: 'spy',
        title: '',
        desc: '<span class="clr-blue-dark fw-bold">3x more effective in threat hunting</span> through innovations in network anomaly detection.',
      },
    ],
  }

  sanitizeYoutubeUrl(url: string) {
    return this.sanitizer?.bypassSecurityTrustResourceUrl(url);
    // return ""
  }

  generateYoutubeSrcDoc(url: string) {
    console.log(this.sanitizer)
    return this.sanitizer?.bypassSecurityTrustHtml(
      `<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/${url}?autoplay=1><img src=https://img.youtube.com/vi/${url}/hqdefault.jpg loading="lazy"><span>▶</span></a>`,
    );
    // return ""
  }

  blogdata = [
    {
      id: 7,
      readtime: '10 mins read',
      background: '#0468BF',
      color: 'white',
      date: '' as string | any,
    },
    {
      id: 9,
      readtime: '8 mins read',
      background: '#F2B705',
      color: 'black',
      date: '' as string | any,
    },
    {
      id: 8,
      readtime: '5 mins read',
      background: '#EF8354',
      color: 'black',
      date: '' as string | any,
    },
    {
      id: 6,
      readtime: '8 mins read',
      background: '#E43A3A',
      color: 'white',
      date: '' as string | any,
    },
    {
      id: 5,
      readtime: '10 mins read',
      background: '#0468BF',
      color: 'white',
      date: '' as string | any,
    },
    {
      id: 4,
      readtime: '5 mins read',
      background: '#F2B705',
      color: 'black',
      date: '' as string | any,
    },
  ];




  numbers = Array.from({ length: 3 }, (_, i) => i);


  routeToPage(page: any) {
    // console.log(page);
    this.router.navigate(page);
  }

  routeToCase(item: any) {
    // console.log(item);
    this.router.navigateByUrl(`/resources/sd-wanusecases#${item.id}`);
  }
  ngOnInit(): void {

    this.canonicalService.setCanonicalTag()

    this.videos = [
      {
        srcdoc: this.generateYoutubeSrcDoc('PUu4_-0eXSc'),
        url: this.sanitizeYoutubeUrl('https://www.youtube.com/embed/PUu4_-0eXSc'),
        title: 'COSGrid MicroZAccess - An Uncomplicated Zero Trust Network Access - Product Overview Demo',
      },
      {
        srcdoc: this.generateYoutubeSrcDoc('CXwhA86gEsM'),
        url: this.sanitizeYoutubeUrl('https://www.youtube.com/embed/CXwhA86gEsM'),
        title: 'Zero Trust Access to Cloud Application and Multi - cloud Infra using MicroZAccess - Demo Use case',
      },
      {
        srcdoc: this.generateYoutubeSrcDoc('42b0T7EwXkE'),
        url: this.sanitizeYoutubeUrl('https://www.youtube.com/embed/42b0T7EwXkE'),
        title: 'Zero Trust Security Insights #2 - ZTNA Product Considerations',
      },
      {
        srcdoc: this.generateYoutubeSrcDoc('CrzO3zA5qls'),
        url: this.sanitizeYoutubeUrl('https://www.youtube.com/embed/CrzO3zA5qls'),
        title: 'Zero Trust Security Insights #1 - Invisible the Visible',
      },
      {
        srcdoc: this.generateYoutubeSrcDoc('90BqQRpM8qo'),
        url: this.sanitizeYoutubeUrl('https://www.youtube.com/embed/90BqQRpM8qo'),
        title: 'COSGrid MicroZAccess - Concept Video - ZTNA',
      },
    ];

    if (this.fetchMetaService.getData() == false) {
      let metatags = [];
      this.apollo
        .watchQuery({
          query: META_QUERY,
        })
        .valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe((result) => {
          console.log("result in home page", result)
          let meta = [];
          meta = (result.data as any).metaTags.data.map((metaTag: any) => ({
            ...metaTag,
          }));
          for (let i = 0; i < meta.length; i++) {
            metatags[meta[i].attributes.URL_ID] = meta[i];
          }
          // console.log("Meta Tags : ", this.metatags);
          this.fetchMetaService.storeData(metatags);
          let metaTagsData = this.fetchMetaService.getData();
          // console.log("Meta Tags ", metaTagsData);

          this.titleService.setTitle(metaTagsData['home'].attributes.title);
          this.metaService.updateTag({
            name: 'description',
            content: metaTagsData['home'].attributes.description,
          });
          this.metaService.updateTag({
            name: 'keywords',
            content: metaTagsData['home'].attributes.keywords,
          });
        });
    } else {
      console.log("Data Alreday Fetched");
      const data = this.fetchMetaService.getData();
      this.titleService.setTitle(data['home'].attributes.title);
      this.metaService.updateTag({
        name: 'description',
        content: data['home'].attributes.description,
      });
      this.metaService.updateTag({
        name: 'keywords',
        content: data['home'].attributes.keywords,
      });
    }
    this.apollo
      .watchQuery({
        query: BLOG_QUERY,
      })
      .valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        console.log("blog result", result)
        this.blogs = (result.data as any).blogs.data.map((blog: any) => ({
          ...blog,
          image: blog.attributes.Title_image.data.attributes.url,
        }));

        for (let i = 0; i < 3; i++) {
          let myDate = new Date(this.blogs[i].attributes.Dateofpublish);
          this.blogdata[i].date = this.datepipe.transform(myDate, 'dd-MMM-yyyy');
        }
      });
  }


  routeToblog(blog: any) {
    this.router.navigateByUrl(`/resources/blogs/${blog.attributes.idUrl}`, {
      state: {
        blog,
      },
    });
  }
  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
