<app-carousel></app-carousel>

<!-- about -->

  

<section class="px-md-5 my-5">
    <div class="container-fluid shadow d-flex flex-column align-items-center p-3">
        <p class="fs-6">Welcome to <span class="clr-blue-dark">Z</span>3 SASE</p>
        <h3 class="h3 fw-bold text-center">Embrace the power of <span class="clr-blue-dark">Z</span>ero Trust, <span
                class="clr-blue-dark">Z</span>ero Glitch, <span class="clr-blue-dark">Z</span>ero Touch</h3>
        <p class="fs-6">COSGrid Networks empowers your business with seamless security, industry-leading protection and
            superior connected experience with <span class="clr-blue-dark">Smart SASE NetSecOps</span></p>
        <div class="d-flex justify-content-md-between justify-content-center flex-column flex-md-row h-100 w-100">
            <div *ngFor="let item of about"
                class="d-flex flex-column align-items-center justify-content-center h-100 w-100">
                <img [src]="item.imgUrl" [alt]="item.alt" [class]="item.class" loading="lazy">
                <p [innerHTML]="item.desc" class="text-center mt-4"></p>
            </div>
        </div>
    </div>
</section>


<section class="px-md-5 my-5 px-2 d-flex justify-content-center align-items-center">
    <img src="https://vcdn.cosgrid.com/website/assets4/home/homepage-content.webp" alt="about" class="w-50" loading="lazy">
</section>

<!-- awards -->
<section class="px-md-5 my-5">
    <app-association [data]="awards" title="Awards and Recognition" headDirection="left"></app-association>
</section>

<section class="px-md-5 my-5 px-2">
    <div class="row justify-content-center">
        <h1 class="clr-blue-dark fw-bold text-center mb-5 h2">Our Products</h1>
        <div *ngFor="let item of ourProducts"
            class="col-12 col-md-4 d-flex justify-content-center align-items-center p-2 p-lg-3 my-3 ">
            <div class="hover-popup shadow rounded w-100 h-100">
                <a class="d-flex align-items-center justify-content-center flex-column w-100 h-100 text-decoration-none clr-black-dark"
                    [routerLink]="item.link" [fragment]="item?.fragment"><img [src]="item.imgUrl" [alt]="item.name"
                        [class]="item.width" loading="lazy" />
                    <p class="mt-2 clr-black">{{item.name}}</p>
                </a>
            </div>


        </div>
    </div>
</section>

<!-- our customers -->
<section class="px-md-5 my-5">
    <div class="row justify-content-center">
        <h1 class="clr-blue-dark fw-bold text-center h2 mb-5">Our Customers</h1>
        <ngu-carousel [inputs]="carouselTile" [dataSource]="customerSliderList">
            <div class="item d-flex justify-content-center align-items-center" *nguCarouselDef="let item; let i = index"
                style="border: none; box-shadow: none">
                <img [src]="item.imgURL" [alt]="item?.alt" [class]="item?.class" loading="lazy" />
            </div>
        </ngu-carousel>
    </div>
</section>

<!-- testimonials -->
<section class="px-md-5 my-5">
    <app-testimonials> </app-testimonials>
</section>

<!-- Integration support -->
<section class="px-md-5 my-5">
    <div class="p-3"
        style="background: linear-gradient(90deg, #6801AF 0%, #00B3F0 100%); border-top-right-radius: 20px">
        <h1 class="fw-bold clr-white h2">Integration Support</h1>
        <h5 class="clr-white fs-5">Cloud Integration Support & SSO for the following platforms</h5>
    </div>
    <div class="row d-flex justify-content-center align-items-center h-100 py-lg-5 py-1 integration-row">
        <div class="col-md-3 col-12 image-box d-flex justify-content-center align-items-center hover-popup"
            *ngFor="let item of Integration">
            <a [href]="item.link" target="_blank"
                class="d-flex justify-content-center w-90 h-90 p-3 image-anchor bg-clr-white"><img [src]="item?.imgURL"
                    [alt]="item?.name" loading="lazy" class="img-fluid5" style="cursor: pointer"
                    style="max-height: 80px; object-fit: contain" [attr.width]="item.width" />
            </a>
            <!-- </div> -->
        </div>
    </div>
</section>

<!-- association and industry partner -->
<section class="px-md-5 my-5">
    <div class="row justify-content-center">
        <h1 class="clr-blue-dark fw-bold text-center h2 mb-5">Associations and Industry partners</h1>
        <ngu-carousel [inputs]="carouselTile" [dataSource]="associateWith">
            <div class="item d-flex justify-content-center align-items-center" *nguCarouselDef="let item; let i = index"
                style="border: none; box-shadow: none">
                <img [src]="item.imgURL" [alt]="item?.alt" [class]="item.class" loading="lazy" />
            </div>
        </ngu-carousel>
    </div>
</section>

<!-- video tutorials -->
<section class="px-md-5 my-5">
    <div class="row justify-content-center position-relative">
        <h1 class="clr-blue-dark fw-bold text-center h2 mb-5">Video Tutorials</h1>
        <ngu-carousel #videoCarousel [inputs]="carouselVideoTile" [dataSource]="videos">
            <div class="item" *nguCarouselDef="let video; let i = index" style="border: none; box-shadow: none">
                <div class="m-2 d-flex flex-column align-items-center">
                    <iframe width="300" height="195" [srcdoc]="video.srcdoc" title="YouTube video player"
                        frameborder="0" loading="lazy"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen></iframe>
                    <p class=" mt-5 mx-5">{{ video.title }}</p>
                </div>
            </div>
            <button NguCarouselPrev class="nav-btn left-nav" [style.opacity]="videoCarousel.isFirst() ? 0.2:0.7"><i
                    class="fas fa-angle-left"></i></button>
            <button NguCarouselNext class="nav-btn right-nav" [style.opacity]="videoCarousel.isLast() ? 0.2:0.7"><i
                    class="fas fa-angle-right" style="user-select: auto;"></i></button>
        </ngu-carousel>
    </div>
</section>

<!-- why cosgrid -->
<section class="px-md-5 my-5 px-2">
    <app-features-list title="COSGrid Advantage" subtitle="" [data]="whyEnterprisesChooseCosgrid"></app-features-list>
</section>
<!-- <section class="px-md-5 my-5">
    <div class="container-fluid">
        <p class="text-center fw-bold clr-blue-dark fw-bold h2">Why COSGrid ?</p>
        <div class="pt-4">
            <table-list [data]=""
                baseUrl="https://vcdn.cosgrid.com/website/assets/home/why-choose-cosgrid/"></table-list>
        </div>
    </div>
</section> -->

<!-- blogs -->
<section class="my-5 px-md-5">
    <div class="container-fluid">
        <h1 class="fw-bold text-center clr-blue-dark h2">Blogs</h1>
        <div class="d-flex justify-content-around align-items-center flex-wrap">
            <div *ngFor="let i of numbers" class="card mt-4" style="width: 20rem; padding: 10px; border-radius: 20px"
                [ngStyle]="{ background: blogdata[i].background, color: blogdata[i].color }">
                <div class="date-container text-center">
                    <span class="font-bold">{{ blogdata[i].date.split('-')[0] }}</span><br />
                    <span>{{ blogdata[i].date.split('-')[1] }}&nbsp;{{ blogdata[i].date.split('-')[2] }}</span>
                </div>
                <img class="card-img-top" [src]="blogs?.[i]?.image" alt="Card image cap" loading="lazy" />
                <div class="card-body">
                    <p class="card-title text-white fs-6">{{ blogs?.[i]?.attributes.Title }}</p>

                    <p class="ml-auto text-white" (click)="routeToblog(blogs?.[i])" style="cursor: pointer">
                        Read more<i class="fa fa-angle-right fa-fw fa-sm"></i>
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>